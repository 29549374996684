@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  height: 100%;
  background-color: #f9f9f9;
}

*:focus-visible {
  outline: none;
}